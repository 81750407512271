import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const OurStoriesCSRPageGrid = ({ stories }) => {
    return (
        <div className="container">
            {stories?.map((story, i) => (
                <div className="csr-page-our-stories__grid" key={i}>
                    <div className="csr-page-our-stories__grid-main-item">
                        <div className="csr-page-our-stories__grid-main-item-img">
                            {/* <StaticImage src={story.image} alt={`story ${i + 1}`} /> */}
                            <img src={story.image} alt={`Story ${i + 1}`} />
                        </div>
                        <div className="csr-page-our-stories__grid-main-item__text">
                            <h3 className="text-h3 text-clr-primary text-fw-medium gap-05x">
                                {story.title}
                            </h3>
                            <p className="text-p3 text-clr-primary gap-05x">{story.date}</p>
                            <p className="text-p3 text-clr-secondary gap-05x">
                                {story.description}
                            </p>
                            {/* <Link to="/contact-us" className="sdn-link">
                                {story.btn}
                            </Link> */}
                            {story.btn && (
                                <Link to={story.path || "/"}>
                                    <Link className="sdn-link">{story.btn}</Link>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OurStoriesCSRPageGrid;
