import React from "react";
import Layout from "../../../components/Layout";
import Seo from "../../../components/seo";
import ogIMage from "../../../images/og.png";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import OurStoriesCSRPageGrid from "../../../components/OurStoriesCSRPageGrid";
import story1Img from "../../../images/csrpage/story-1.png";
import story2Img from "../../../images/csrpage/story-2.png";
import story3Img from "../../../images/csrpage/story-3.png";
import story4Img from "../../../images/csrpage/story-4.png";

const CSRPage = () => {
    const ourStories = [
        {
            image: story1Img,
            title: "Sedin and Round Table India Empower Panchayat Union Primary School in Mannivakkam, Tamil Nadu",
            date: "July, 2023",
            description:
                "Sedin Technologies is immensely proud to have played a pivotal role in a transformative project, collaborating with Round Table India, to construct a new Six Classroom Block and Toilet Block at Mannivakkam Primary School.",
            btn: "Explore More",
            path: "/about/csr/building-dreams-empowering-education",
        },
        {
            image: story2Img,
            title: "Sedin Creates Safe Spaces for School Children in Karur, Tamil Nadu",
            date: "June, 2023",
            description:
                "Sedin is honored to have played a role in completing an infrastructure element for the elementary school in Karur, Tamil Nadu. The project commenced in June 2023 delivers a boost to the school's security infrastructure.",
            btn: "Explore More",
            path: "/about/csr/fostering-dreams-igniting-learning",
        },
        {
            image: story3Img,
            title: "Breaking Barriers, Unleashing Talents: Sedin Backs All Girls Hackathon in Chennai, Tamil Nadu",
            date: "April, 2023",
            description:
                "Sedin is proudly sponsoring the All Girls Hackathon. This transformative event aims to inspire and empower girls, young women & mothers from low-income communities to embark on a journey of coding and discovery. ",
            btn: "Explore More",
            path: "/about/csr/breaking-barriers-supporting-young-girls",
        },
        {
            image: story4Img,
            title: "Sedin and Paramhansa Yogananda Trust Brighten Lives of Destitute Mothers in Vrindavan, Uttar Pradesh",
            date: "August, 2022",
            description:
                "In an inspiring collaboration, Sedin and Paramhansa Yogananda Trust have come together for the Maatri Seva Program. This community-driven initiative aims to support elderly destitute mothers  residing in Vrindavan. ",
            btn: "Explore More",
            path: "/about/csr/changing-lives-spreading-smiles",
        },
    ];
    return (
        <Layout>
            <Seo
                title={" CSR "}
                description="Discover Sedin’s commitment to Corporate Social Responsibility initiatives. Explore how we're creating a positive difference through purpose-driven CSR efforts. "
                image={ogIMage}
            />
            {/* csr page hero section */}
            <section className="csr-page-hero">
                <div className="container container--first-v3">
                    <div className="csr-page-hero__text gap-2x">
                        <div className="section-tag gap-2x">Corporate Social Responsibility</div>
                        <div className="text-h1 text-clr-primary text-fw-medium">
                            Building Stronger Communities for the Future
                        </div>
                    </div>
                    <div className="csr-page-hero__grid">
                        <div className="csr-page-hero__grid-item">
                            <StaticImage
                                src="../../../images/csrpage/csrpage-hero-img-1.webp"
                                placeholder="blurred"
                                alt="CSR"
                                className="csr-img"
                                loading="lazy"
                            />
                        </div>
                        <div className="csr-page-hero__grid-item">
                            <div className="csr-page-hero__text">
                                <h3 className="text-h2 text-clr-primary text-fw-medium gap-05x">
                                    Making a Difference
                                </h3>
                                <p className="text-p3 text-clr-secondary gap-05x">
                                    We fuse technology with empathy, paving the way for a new era of
                                    corporate social responsibility. We are a family of changemakers
                                    united by a common goal- forge meaningful relationships and
                                    create lasting impacts, leaving an indelible mark on the lives
                                    we touch.
                                </p>
                                <p className="text-p3 text-clr-secondary gap-05x">
                                    We dare to push boundaries of what's possible & envision a
                                    better tomorrow by supporting the communities in which we
                                    operate and fostering sustainable development.
                                </p>
                                <Link
                                    className="sdn-cta-btn text-p4 text-fw-medium text-decor-none"
                                    target="_blank"
                                    to="/csr-policy"
                                >
                                    Read Our CSR Policy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* csr page our stories section */}
            <section className="csr-page-our-stories">
                <div className="container container--mid-v2">
                    <div className="csr-page-our-stories__text">
                        <h2 className="text-h2 text-clr-primary text-fw-medium">
                            Our Stories of Impact
                        </h2>
                    </div>
                </div>
                <div>
                    <OurStoriesCSRPageGrid stories={ourStories} />
                </div>
            </section>
            {/* our commitments section  */}
            <section className="csr-page-our-commitments">
                <div className="container container--last-v2">
                    <div className="csr-page-our-commitments__text">
                        <h2 className="text-h2 text-clr-primary text-fw-medium gap-2x">
                            Our Commitments
                        </h2>
                    </div>
                    <div className="csr-page-our-commitments__grid">
                        <div className="csr-page-our-commitments__grid-items">
                            <StaticImage
                                src="../../../images/csrpage/gender-equality-1.png"
                                alt="commitment-img"
                                loading="lazy"
                                placeholder="blurred"
                                className="commitment-img"
                            />
                        </div>
                        <div className="csr-page-our-commitments__grid-items">
                            <StaticImage
                                src="../../../images/csrpage/quality-education-1.png"
                                alt="commitment-img"
                                loading="lazy"
                                placeholder="blurred"
                                className="commitment-img"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default CSRPage;
